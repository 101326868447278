// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menus-bbq-menu-js": () => import("./../../../src/pages/menus/bbq-menu.js" /* webpackChunkName: "component---src-pages-menus-bbq-menu-js" */),
  "component---src-pages-menus-desert-menu-js": () => import("./../../../src/pages/menus/desert-menu.js" /* webpackChunkName: "component---src-pages-menus-desert-menu-js" */),
  "component---src-pages-menus-hog-roast-menu-js": () => import("./../../../src/pages/menus/hog-roast-menu.js" /* webpackChunkName: "component---src-pages-menus-hog-roast-menu-js" */),
  "component---src-pages-menus-js": () => import("./../../../src/pages/menus.js" /* webpackChunkName: "component---src-pages-menus-js" */),
  "component---src-pages-menus-salad-menu-js": () => import("./../../../src/pages/menus/salad-menu.js" /* webpackChunkName: "component---src-pages-menus-salad-menu-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

